import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';
class BuzonDatosDescargas{
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/buzonDatosDescarga/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrarDato(id){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/buzonDatosDescarga/borrar/'+id,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    descargarFolleto(nombre){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/buzonDatosDescarga/descargar/'+nombre,
            headers: authHeader(),
            responseType: 'Blob'
        }
        console.log(axiosRequest.url)
        return axios(axiosRequest);
    }
}
export default new BuzonDatosDescargas();