<template lang="html">
  <section class="v-buzon-descargas">
    <h3 class="text-left">
      Buzón de entrada - Datos para descarga de folletos
    </h3>
    <div class="d-flex flex-wrap mt-4">
      <Toolbar class="p-mb-4 col-12">
        <template #left>
          <Button
            label="Borrar"
            icon="pi pi-trash"
            class="p-button-danger ml-2"
            :disabled="!selectedDatos || !selectedDatos.length"
            @click="deleteDatosDialog = true"
          />
        </template>
        <template #right> </template>
      </Toolbar>
      <DataTable
        :value="datos"
        :paginator="true"
        class="p-datatable-customers"
        :rows="10"
        :resizableColumns="true"
        columnResizeMode="fit"
        dataKey="xdescargaid"
        :rowHover="true"
        :selection.sync="selectedDatos"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            Lista de Datos para descargas
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global']"
                placeholder="Búsqueda global"
              />
            </span>
          </div>
        </template>
        <template #empty> No Datos found. </template>
        <template #loading> Loading Datos data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="id" header="Id" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            {{ slotProps.data.xdescargaid }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xdescargaid']"
              class="p-column-filter"
              placeholder="ID"
              style="width: 5em !important"
            />
          </template>
        </Column>
        <Column
          header="Nombre"
          :sortable="true"
          sortField="xnombre"
          filterField="xnombre"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nombre</span>
            {{ slotProps.data.xnombre }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xnombre']"
              class="p-column-filter"
              placeholder="Nombre"
            />
          </template>
        </Column>
        <Column
          header="Compañia"
          :sortable="true"
          sortField="xcompañia"
          filterField="xcompañia"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Compañia</span>
            {{ slotProps.data.xcompañia }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xcompañia']"
              class="p-column-filter"
              placeholder="Compañia"
            />
          </template>
        </Column>
        <Column
          header="Email"
          :sortable="true"
          sortField="xemail"
          filterField="xemail"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>
            {{ slotProps.data.xemail }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xemail']"
              class="p-column-filter"
              placeholder="Email"
            />
          </template>
        </Column>

        <Column field="nombreFolleto" header="Folleto" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Folleto</span>
            {{ slotProps.data.nombreFolleto }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['nombreFolleto']"
              class="p-column-filter"
              placeholder="Folleto"
              style="width: 5em !important"
            />
          </template>
        </Column>

        <Column field="tituloServicio" header="Servicio" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Servicio</span>
            {{ slotProps.data.tituloServicio }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['tituloServicio']"
              class="p-column-filter"
              placeholder="Servicio"
              style="width: 5em !important"
            />
          </template>
        </Column>

        <Column field="tituloPerfil" header="Perfiles" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Perfiles</span>
            {{ slotProps.data.tituloPerfil }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['tituloPerfil']"
              class="p-column-filter"
              placeholder="Perfiles"
              style="width: 5em !important"
            />
          </template>
        </Column>

        <Column
          field="xfecha"
          header="Fecha"
          sortField="xfecha"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Fecha</span>
            <span>{{ slotProps.data.xfecha.split("T")[0] }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xfecha']"
              class="p-column-filter"
              placeholder="Fecha"
              style="width: 5em !important"
            />
          </template>
        </Column>

        <Column
          headerStyle="width: 8rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <vs-button
              class="d-inline"
              color="danger"
              @click="
                deleteDatosDialog = true;
                selectedDatos = [slotProps.data];
              "
            >
              <i class="pi pi-trash text-xl"></i>
            </vs-button>
            <vs-button
              class="d-inline"
              color="rgb(109, 207, 195)"
              @click="descargarFolleto(slotProps.data.direccionFolleto)"
            >
              <i class="pi pi-download text-xl"></i>
            </vs-button>
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="deleteDatosDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
        :baseZIndex="9002"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar estos datos? </span>
          <ul class="text-left ml-auto mr-auto">
            <li v-for="dato in selectedDatos" :key="dato.xdescargaid">
              Id: {{ dato.xdescargaid }}
            </li>
          </ul>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="deleteDatosDialog = false"
          />
          <Button
            label="Si"
            icon="pi pi-check"
            class="p-button-danger"
            @click="deleteDatos"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="js">
import BuzonDatosDescargas from '../../services/buzonDatosDescargas.service'
import ViewService from "../../services/view.service.js"
import API_URL from"../../backend.js"
  export default  {
    name: 'v-buzon-descargas',
    props: [],
    mounted () {
      this.cargarDatos()
    },
    data () {
      return {
        datos:[],
        selectedDatos:[],
        filters: {},
        deleteDatosDialog:false,
      }
    },
    methods: {
      cargarDatos(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
          this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";
          
          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });


        BuzonDatosDescargas.getTodos().then((response)=>{
          this.datos = response.data;
          /* this.datos.forEach((dato)=>{
            dato.direccionFolleto = API_URL+dato.direccionFolleto
          }) */
          this.loader.close()
        }).catch((error)=>{
          console.error(error);
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error,
          });
          this.loader.close()
        })
      },
      deleteDatos(){
        let promesasDelete=[];
        for(let i = 0; i<this.selectedDatos.length; i++) {
          promesasDelete.push(BuzonDatosDescargas.borrarDato(this.selectedDatos[i].xdescargaid))
        }
        Promise.all(promesasDelete).then((res) => {
          res.forEach((response) => {
            if(response.data){
              this.$toast.add({
                severity: response.data.success ? 'info' : 'error',
                summary: 'Borrado',
                detail: response.data.description,
                life: response.data.success ? 4000 : null
              })
            }
          })
          this.cargarDatos();
          this.deleteDatosDialog = false;
        }).catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail:error
          })
        })
      },
      descargarFolleto(direccionFolleto){
        BuzonDatosDescargas.descargarFolleto(direccionFolleto.split("/")[direccionFolleto.split("/").length-1]).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', direccionFolleto.split("/")[direccionFolleto.split("/").length-1]);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        })
      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-buzon-descargas {
}
</style>
